import React from "react";
import HeroImage from "./hero.PNG";

const heroStyles = {
  minHeight: "400px",
  backgroundImage: `url(${HeroImage})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

export const Hero = () => {
  return (
    <section className="hero" style={heroStyles}>
      <div className="hero__text-container">
        <h1>Foliage</h1>
        <p>By Gemma Enriquez</p>
      </div>
    </section>
  );
};
