import React from "react";
import { Hero } from "./Sections/Hero";
import MaterialsImage from "./Sections/images/materials.PNG";
import ScuiplureImage from "./Sections/images/sculpture.PNG";
import HeadshotImage from "./Sections/images/headshot.PNG";
import ThanksImage from "./Sections/images/heart.png";
import "./styles/main.scss";

const App = () => {
  return (
    <div className="main__container">
      <Hero />
      <section className="section gutter quote">
        <div className="quote__container">
          <p>“Each day is a dry leaf, which never comes back to life again.”</p>
          <p>- Shine S.</p>
        </div>
      </section>
      <section className="section">
        <img
          src={ScuiplureImage}
          alt="self portrait of Gemma Enriquea"
          width="100%"
          height="auto"
        />
        <div className="gutter">
          <h4>About</h4>
          <div className="text__container">
            <p>
              Foliage is a varied bouquet of faux flora. The branches are made
              of wire wrapped in newspaper and magazine cut leaves.
            </p>
            <p>
              I was inspired by the changing of seasons. The moment when leaves
              go from green to yellow to orange to red. The life cycle of
              foliage never ceases to amaze me. It reminds me that the only
              constant thing in life is change.
            </p>
            <p>
              In that same spirit I am reminded of the people that were once in
              my life and no longer are. I honor them and all the memories
              shared in this ode to nature.
            </p>
          </div>
        </div>
      </section>
      <section id="materials">
        <img
          src={MaterialsImage}
          alt="self portrait of Gemma Enriquea"
          width="100%"
          height="auto"
        />
        <div className="gutter">
          <h4>Materials</h4>
          <div className="text__container">
            <p>
              Upcycled materials are the backbone of this piece. It was
              important to source what I needed from within my community.
            </p>
            <div className="material__container">
              <h6>Magazines and newspaper</h6>
              <p>Donated by my neighbours and friends.</p>
            </div>
            <div className="material__container">
              <h6>Styrofoam</h6>
              <p>
                Found at my local recycle bin. Btw, styrofoam is not recyclable.
              </p>
            </div>
            <div className="material__container">
              <h6>Wire</h6>
              <p>Bought at my local bazaar.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section gutter" id="thanks">
        <img
          src={ThanksImage}
          alt="self portrait of Gemma Enriquea"
          width="100px"
          height="auto"
        />
        <h4>Special Thanks</h4>
        <div className="text__container">
          <p>
            A very special thanks to{" "}
            <a
              href="https://www.instagram.com/etualcafe"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Etual
            </a>{" "}
            for hosting my piece, Foliage, in their establishment. I find myself
            coming together with other people over a cup of coffee, so it only
            seemed right to share this piece in a space that embodies community.
          </p>
        </div>
      </section>
      <section id="about">
        <h4 className="center">Gemma E.</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={HeadshotImage}
            alt="self portrait of Gemma Enriquea"
            width="80%"
            height="auto"
          />
        </div>
        <div className="gutter" id="special-gutter">
          <div className="text__container">
            <p style={{ textAlign: "center" }}>
              Dallas native living in Madrid. I come from a family of proud
              immigrants who taught me to celebrate life with those around you.
              Living in Spain has given me a better understanding of the
              sacrifices and gains my family experienced when they left their
              home in Mexico.
            </p>
            <p style={{ textAlign: "center" }}>
              I dedicate this piece to my family and friends who have sustained
              me on this ride.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default App;
